import React from 'react'
import { Layout, UserContext } from '../../components/Layout'
import { useForm, Controller } from 'react-hook-form'
import { navigate } from 'gatsby'
import { Button } from '../../components/Button'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { FormControl } from 'baseui/form-control'
import { Input } from '../../components/Input'
import { AccountHeader } from './view'
import { updateUser, LoginResponse, Role } from '../../api/user'
import { ErrorNotification } from '../../components/Notification'
import constants from '../../constants'
import { loginRedirect } from '../../utils'
import { RoleOption, RoleSelect, roleOptions } from '../../components/RoleSelect'

export const AccountEditHeader = () => {
  return <AccountHeader logoutVisible={false} />
}

export interface UpdateUserErrorResponse {
  new?: string[]
  old?: string[]
}

export interface EditAccountInputs {
  preferredName?: string
  fullName?: string
  oldPassword?: string
  newPassword?: string
  role?: RoleOption[]
}

export const AccountEdit = () => {
  const context = React.useContext(UserContext)
  const [errorReasons, setErrorReasons] = React.useState<UpdateUserErrorResponse | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)

  function checkRole(role: RoleOption) {
    return role && role.id === context.role
  }

  function getRole() {
    const role = roleOptions.find(checkRole)
    return role ? [role] : undefined
  }

  React.useEffect(() => {
    if (!context.token) {
      loginRedirect()
    }
  }, [context])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { register, handleSubmit, control, setValue } = useForm<EditAccountInputs>({
    defaultValues: {
      preferredName: context ? context.preferredName || undefined : undefined,
      fullName: context ? context.name || undefined : undefined,
      oldPassword: '',
      newPassword: '',
      role: context ? getRole() : undefined
    }
  })

  const success = (response: LoginResponse) => {
    if (context.setUserContext && context.rememberMe) {
      context.setUserContext(response, context.rememberMe)
      setValue('newPassword', '')
      setValue('oldPassword', '')
      navigate('/account/view')
    }
  }

  const failure = (error: UpdateUserErrorResponse) => {
    setErrorReasons(error)
    setIsLoading(false)
  }

  const onSubmit = (values: EditAccountInputs) => {
    let role_id: Role | undefined
    if (values.role) {
      role_id = values.role[0].id
    }
    const submit_values = {
      preferredName: values.preferredName,
      fullName: values.fullName,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      role: role_id
    }

    if (context.token) {
      setIsLoading(true)
      updateUser(context.token, submit_values, success, failure)
    }
  }

  let newPasswordError = null
  let oldPasswordError = null
  let unknownSubmitError = false

  if (errorReasons) {
    newPasswordError = errorReasons.new ? errorReasons.new.join(' ') : null
    oldPasswordError = errorReasons.old ? errorReasons.old.join(' ') : null
    if (!newPasswordError && !oldPasswordError) {
      unknownSubmitError = true
    }
  }

  const errorMessage = (
    <>
      There was an unknown error while saving changes.
      <br />
      If it persists, please contact {constants.supportEmail}.
    </>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Cell span={[4, 6]}>
          <AccountEditHeader />
        </Cell>
        <Cell span={[4, 6]}>
          <hr />
        </Cell>
        <Cell span={4}>
          <EditField name={'preferredName'} label={'Preferred Name'} register={register} />
          <EditField name={'fullName'} label={'Full name'} register={register} />
          <NonEditableField
            value={context ? context.email || undefined : undefined}
            label={'Email'}
          />
          <PasswordField
            name={'oldPassword'}
            label={'Old Password'}
            errors={oldPasswordError || undefined}
            autoComplete={'current-password'}
            register={register}
          />
          <PasswordField
            name={'newPassword'}
            label={'New Password'}
            errors={newPasswordError || undefined}
            autoComplete={'new-password'}
            register={register}
          />
        </Cell>
        <Cell span={[4, 6]}>
          <NonEditableField
            value={context ? context.unionName || undefined : undefined}
            label={'Union'}
          />
        </Cell>
        <Cell span={4}>
          <Controller rules={{ required: true }} as={RoleSelect} name="role" control={control} />
        </Cell>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={unknownSubmitError} errorMessage={errorMessage} />
        </Cell>
        <Cell span={[2, 3]} align={ALIGNMENT.start}>
          <Button
            kind="secondary"
            type="submit"
            overrides={{
              BaseButton: { style: { width: '100%', maxWidth: '100%', minHeight: '50px' } }
            }}
            isLoading={isLoading}
            data-testid={'submit-button'}
          >
            Save Changes
          </Button>
        </Cell>
        <Cell span={[2, 3]} align={ALIGNMENT.start}>
          <Button
            overrides={{
              BaseButton: { style: { width: '100%', maxWidth: '100%', marginBottom: '10px' } }
            }}
            kind="tertiary"
            type="button"
            data-testid={'cancel-button'}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </Cell>
      </Grid>
    </form>
  )
}

interface FieldProps {
  label: string
}

interface NonEditableFieldProps extends FieldProps {
  value?: string
}

interface EditFieldProps extends FieldProps {
  name?: string
  register: (instance: HTMLInputElement | null) => void
}

interface PasswordFieldProps extends EditFieldProps {
  autoComplete: string
  errors?: string
}

export const EditField = (props: EditFieldProps) => {
  return (
    <FormControl label={props.label}>
      <Input name={props.name} required={true} id={props.name} inputRef={props.register} />
    </FormControl>
  )
}

export const NonEditableField = (props: NonEditableFieldProps) => {
  return (
    <FormControl label={props.label}>
      <Input value={props.value} disabled={true} />
    </FormControl>
  )
}

export const PasswordField = (props: PasswordFieldProps) => {
  return (
    <FormControl label={props.label} error={props.errors}>
      <Input
        name={props.name}
        required={false}
        id={props.name}
        inputRef={props.register}
        placeholder={props.label}
        type="password"
        autoComplete={props.autoComplete}
        data-testid={props.name}
      />
    </FormControl>
  )
}

const LayoutAccountEdit = () => {
  return (
    <Layout showNav={false}>
      <AccountEdit />
    </Layout>
  )
}
export default LayoutAccountEdit
